module.exports = {
    home: { title: "Home", description: "AtkinsRéalis Utility Solutions Home", directoryParts: [], htmlName: "index.html", pageType: "branded" },
    aboutUs: { title: "About us", description: "About AtkinsRéalis Utility Solutions", directoryParts: [], htmlName: "about-us.html", pageType: "branded" },
    products: { title: "Products", description: "Products", directoryParts: [], htmlName: "products.html", pageType: "branded" },
    whatWeOffer: { title: "What we offer", description: "Services that Atkins Utility Solutions offer to our clients", directoryParts: [], htmlName: "what-we-offer.html", pageType: "branded" },
    pricing: { title: "Pricing", description: "AtkinsRéalis Utility Solutions pricing information", directoryParts: [], htmlName: "pricing.html", pageType: "branded" },
    caseStudies: { title: "Case studies", description: "AtkinsRéalis Utility Solutions case studies", directoryParts: [], htmlName: "case-studies.html", pageType: "branded" },
    contactAndHelp: { title: "Contact and help", description: "Contact AtkinsRéalis Utility Solutions or get help", directoryParts: [], htmlName: "contact-and-help.html", pageType: "branded" },
    myOrders: { title: "My orders", description: "Current user's orders", directoryParts: ["account"], htmlName: "my-orders.html", pageType: "branded" },
    myDocuments: { title: "My documents", description: "Current user's documents", directoryParts: ["account"], htmlName: "my-documents.html", pageType: "branded" },
    myDocumentDetails: { title: "My document details", description: "Current user's document details", directoryParts: ["account"], htmlName: "my-document-details.html", pageType: "branded" },
    myNotifications: { title: "My notifications", description: "Current user's notifications", directoryParts: ["account"], htmlName: "my-notifications.html", pageType: "branded" },
    myAccount: { title: "My account", description: "Current user's account details", directoryParts: ["account"], htmlName: "my-account.html", pageType: "branded" },
    selectArea: { title: "Select area", description: "Select an area of interest for a new utility search", directoryParts: ["order"], htmlName: "select-area.html", pageType: "order" },
    selectOptions: { title: "Select options", description: "Select options requested for the order", directoryParts: ["order"], htmlName: "select-options.html", pageType: "order" },
    register: { title: "Register", description: "Register a Utility Solutions account.", directoryParts: [], htmlName: "register.html", pageType: "branded" },
    logIn: { title: "Log in", description: "Please sign in to access your account.", directoryParts: [], htmlName: "log-in.html", pageType: "branded" },
    forgottenPassword: { title: "Forgotten password", description: "Please enter your email and we will send a link to this address which will allow you to reset your password.", directoryParts: [], htmlName: "forgotten-password.html", pageType: "branded" },
    error: { title: "Error", description: "Error page", directoryParts: [], htmlName: "error.html", pageType: "branded" },
    orderDetails: { title: "Order details", description: "Order details", directoryParts: ["account"], htmlName: "order-details.html", pageType: "branded" },
    emailVerify: { title: "Create a new password", description: "Please enter a new password for your account. Note it will need to satisfy our password criteria to help ensure your account is more secure.", directoryParts: [], htmlName: "email-verify.html", pageType: "branded"},
    viewEstimate: { title: "View Estimate", description: "View estimate pricing for the order", directoryParts: ["order"], htmlName: "view-estimate.html", pageType: "order" },
    checkout: { title: "Checkout", description: "Checkout order", directoryParts: ["order"], htmlName: "checkout.html", pageType: "order" },
    confirmation: { title: "Order confirmation", description: "Your order has been successfully placed.", directoryParts: ["order"], htmlName: "confirmation.html", pageType: "branded" },
    paymentComplete: { title: "Payment complete", description: "Payment complete", directoryParts: ["order", "paypal"], htmlName: "payment-complete.html", pageType: "base" },
    paymentCancelled: { title: "Payment cancelled", description: "Payment cancelled", directoryParts: ["order", "paypal"], htmlName: "payment-cancelled.html", pageType: "base" },
    addProducts: { title: "Add products to order", description: "Add products to your existing order.", directoryParts: ["order"], htmlName: "add-products.html", pageType: "branded" },
    utilitySearchReportLandingPage: {title: "Utility Search Report landing page", description: "Utility Search Report landing page", directoryParts: ["landingpages"], htmlName: "utility-search-report-landing-page.html", pageType: "branded" },
    utilitySearchMapLandingPage: {title: "Utility Search Map landing page", description: "Utility Search Map landing page", directoryParts: ["landingpages"], htmlName: "utility-search-map-landing-page.html", pageType: "branded" },
};

