const authenticationTokenKey = "authenticationToken";
const startOrderLocationQueryTextKey = "startOrderLocationQueryText";
const inProgressOrderTokenKey = "inProgressOrderToken";
const startOrderMapBoundsKey = "startOrderMapBounds";
const startOrderSiteGeometryKey = "startOrderSiteGeometry";
const cookiePreferencesSavedKey = "cookiePreferencesSaved";
const allowAnalyticsCookiesKey = "allowAnalyticsCookies";
const payPalOrderIdKey = "payPalOrderId";
const userId = "userId";

/**
 * Persistent storage
 */
class Storage {

    public setUserId(contactId: string): void {
            window.localStorage.setItem(userId, contactId);
    }

    public getAuthenticationToken(): string {
        let authenticationTokenValue: string = window.sessionStorage.getItem(authenticationTokenKey);
        if (!authenticationTokenValue) {
            authenticationTokenValue = window.localStorage.getItem(authenticationTokenKey);
        }
        return authenticationTokenValue;
    }

    public setAuthenticationToken(authenticationTokenValue: string, persist: boolean): void {
        if (persist) {
            // Remember the authentication token for future sessions.
            window.sessionStorage.removeItem(authenticationTokenKey);
            window.localStorage.setItem(authenticationTokenKey, authenticationTokenValue);
        }

        else {
            // Only store the token for this session
            window.sessionStorage.setItem(authenticationTokenKey, authenticationTokenValue);
            window.localStorage.removeItem(authenticationTokenKey);
        }
    }

    public clearAuthenticationToken(): void {
        window.sessionStorage.removeItem(authenticationTokenKey);
        window.localStorage.removeItem(authenticationTokenKey);
    }


    public getStartOrderLocationQueryText(): string {
        return window.sessionStorage.getItem(startOrderLocationQueryTextKey);
    }

    public setStartOrderLocationQueryText(startOrderLocationQueryTextValue): void {
        return window.sessionStorage.setItem(startOrderLocationQueryTextKey, startOrderLocationQueryTextValue);
    }

    public clearStartOrderLocationQueryText(): void {
        return window.sessionStorage.removeItem(startOrderLocationQueryTextKey);
    }

    public getStartOrderMapBounds(): string {
        return window.sessionStorage.getItem(startOrderMapBoundsKey);
    }

    public setStartOrderMapBounds(startOrderMapBoundsValue): void {
        return window.sessionStorage.setItem(startOrderMapBoundsKey, startOrderMapBoundsValue);
    }

    public clearStartOrderMapBounds(): void {
        return window.sessionStorage.removeItem(startOrderMapBoundsKey);
    }

    public getStartOrderSiteGeometry(): string {
        return window.sessionStorage.getItem(startOrderSiteGeometryKey);
    }

    public setStartOrderSiteGeometry(startOrderSiteGeometryValue): void {
        return window.sessionStorage.setItem(startOrderSiteGeometryKey, startOrderSiteGeometryValue);
    }

    public clearStartOrderSiteGeometry(): void {
        return window.sessionStorage.removeItem(startOrderSiteGeometryKey);
    }

    public getInProgressOrderToken(): string {
        return window.sessionStorage.getItem(inProgressOrderTokenKey);
    }

    public setInProgressOrderToken(inProgressOrderTokenValue: string): void {
        window.sessionStorage.setItem(inProgressOrderTokenKey, inProgressOrderTokenValue);
    }

    public clearInProgressOrderToken(): void {
        return window.sessionStorage.removeItem(inProgressOrderTokenKey);
    }

    public getPayPalOrderId(): string {
        return window.sessionStorage.getItem(payPalOrderIdKey);
    }

    public setPayPalOrderId(payPalOrderIdValue: string): void {
        window.sessionStorage.setItem(payPalOrderIdKey, payPalOrderIdValue);
    }

    public clearPayPalOrderId(): void {
        return window.sessionStorage.removeItem(payPalOrderIdKey);
    }

    public getAllowAnalyticsCookies(): boolean {

        const cookieValue = window.localStorage.getItem(allowAnalyticsCookiesKey);

        if ("0" === cookieValue)
        {
            return false;
        }
        else if ("1" == cookieValue)
        {
            return true;
        }
        else
        {
            return null;
        }
    }

    public setAllowAnalyticsCookies(newValue: boolean): void {
        window.localStorage.setItem(allowAnalyticsCookiesKey, newValue ? "1" : "0");
    }

    public clearAllowAnalyticsCookies(): void {
        return window.localStorage.removeItem(allowAnalyticsCookiesKey);
    }

    public getCookiePreferencesSaved(): boolean {
        return "1" === window.localStorage.getItem(cookiePreferencesSavedKey);
    }

    public setCookiePreferencesSaved(newValue: boolean): void {
        window.localStorage.setItem(cookiePreferencesSavedKey, newValue ? "1" : "0");
    }

    public clearCookiePreferencesSaved(): void {
        return window.localStorage.removeItem(cookiePreferencesSavedKey);
    }
}

// Create and export singleton instance.
const storage = new Storage();
export default storage;