import BaseClient from "../baseClient";
import GeneralConfigurationResponse from "./generalConfigurationResponse";
import OsApiConfigurationResponse from "./osApiConfigurationResponse";

export default class ConfigurationClient extends BaseClient {

    public async getOsApiConfigurationAsync() : Promise<OsApiConfigurationResponse> {
        
        const recaptchaToken = await this.recaptchaChallengeAsync('configuration_os_api')

        const response = await this.makeRequestAsync({
            controller: 'configuration', 
            action: 'os-api', 
            method: 'get', 
            queryParams: [
                { name: 'recaptchaToken', value : recaptchaToken }
            ], 
        });
        return response as OsApiConfigurationResponse;
    }


    /**
     * Gets the general configuration from the server.
     * @returns The configuration response object.
     */
    public async getGeneralConfigurationAsync() : Promise<GeneralConfigurationResponse> {
        const response = await this.makeRequestAsync({controller: 'configuration', action: 'general', method: 'get'});
        return response as GeneralConfigurationResponse;
    }

}
