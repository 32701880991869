import WebHostRequest from "./webHostRequest";
import WebHostClientError from "./webHostClientError";

import { RecaptchaAction } from "../recaptchaActions";
import { ReCaptcha } from "../recaptcha";
import storage from "../storage";
import ErrorInfo from "./errorInfo";
import globals from '../globals';


export default abstract class BaseClient 
{

    protected async makeRequestAsync(requestInfo: WebHostRequest): Promise<any> {

        const requestInit: RequestInit = {
            method: requestInfo.method,
            headers: {
                'Accept': 'application/json',
            }
        };

        const authenticationToken: string = storage.getAuthenticationToken();
        if (authenticationToken)
        {
            requestInit.headers["Authorization"] = "Bearer " + authenticationToken;
        }

        if (null != requestInfo.body)
        {
            requestInit.headers['Content-Type'] = 'application/json';
            requestInit.body = JSON.stringify(requestInfo.body);
        }

        let requestUrl = `/api/${requestInfo.controller}/${requestInfo.action}`;

        if (null != requestInfo.queryParams)
        {
            const query = requestInfo.queryParams
                .map<string>(p => `${encodeURIComponent(p.name)}=${encodeURIComponent(p.value)}`)
                .join("&");

            requestUrl = `${requestUrl}?${query}`;
        }

        const response = await fetch(requestUrl, requestInit);
        
        // Read response content
        let contentObj: any = null;
        let contentMessage: string = null;

        const responseContentType = response.headers.get('Content-Type');
        if (responseContentType && 'application/json' == responseContentType.split(";")[0].trim())
        {
            contentObj = await response.json();    
        }
        else 
        {
            contentMessage = await response.text();
        }

        // Was the request successful?
        const success = 200 <= response.status && response.status <= 299;
        if (!success)
        {
            const error: WebHostClientError = {
                status : response.status, 
                statusText: response.statusText, 
                content: contentObj as ErrorInfo,
                contentMessage: contentMessage,
            }; 
            throw error;
        }

        return contentObj;
    }

    /**
     * Performs the ReCAPTCHA challenge and returns the response token.
     * @param action The ReCAPTCHA action name.
     * @returns The ReCAPTCHA token to send to the server for validation.
     */ 
    protected async recaptchaChallengeAsync(action: RecaptchaAction) : Promise<string>
    {
        // Get global recaptcha object
        const grecaptcha = window['grecaptcha'] as ReCaptcha;
        if (null == grecaptcha)
        {
            throw "Recaptcha is not loaded";
        }

        // Get token
        const token: string = await new Promise<string>((resolve, reject) => {
            grecaptcha.ready(() => {
                grecaptcha.execute(globals.config.reCaptchaSiteKey, { action: action })
                    .then(token => resolve(token))
            });
        });

        return token;
    }

}
