import GeneralConfigurationResponse from "./webHostClient/configuration/generalConfigurationResponse";

/**
 * Global parameters object
 */
class Globals 
{
    public config: GeneralConfigurationResponse = null;
}



// Singleton instance
const instance = new Globals();
export default instance;