import BaseClient from "../baseClient";
import EmailVerifyRequestBody from "./emailVerifyRequestBody";
import ForgottenPasswordRequestBody from "./forgottenPasswordRequestBody";
import GetTokenInfoRequest from "./getTokenInfoRequest";
import GetTokenInfoResponse from "./getTokenInfoResponse";
import LogInRequestBody from "./logInRequestBody";
import LogInResponseBody from "./logInResponseBody";
import PasswordComplexityRequestBody from "./passwordComplexityRequestBody";
import PasswordComplexityResponseBody from "./passwordComplexityResponseBody";
import RegistrationRequest from "./registrationRequest";
import RegistrationResponse from "./registrationResponse";
import UpdatePasswordRequest from "./updatePasswordRequest";

/**
 * Authentication client.
 */
export default class AuthenticationClient extends BaseClient {

    public async logInAsync(request: LogInRequestBody) : Promise<LogInResponseBody> {

        request.recaptchaToken = await this.recaptchaChallengeAsync('authentication_log_in');

        const response = await this.makeRequestAsync({
            controller: 'authentication', 
            action: 'log-in', 
            method: 'post', 
            body: request, 
        });
        return response as LogInResponseBody;
    }

    public async registerNewUserAsync(request: RegistrationRequest) : Promise<RegistrationResponse> {

        request.recaptchaToken = await this.recaptchaChallengeAsync('authentication_register');

        const response = await this.makeRequestAsync({
            controller: 'authentication',
            action: 'register-new-user',
            method: 'post',
            body: request,
        });

        return response as RegistrationResponse;
    }

    public async forgottenPasswordAsync(request: ForgottenPasswordRequestBody) : Promise<void> {

        request.recaptchaToken = await this.recaptchaChallengeAsync('authentication_forgotten_password');

        await this.makeRequestAsync({
            controller: 'authentication',
            action: 'forgotten-password',
            method: 'post',
            body: request,
        });
    }

    public async getPasswordComplexityAsync(request: PasswordComplexityRequestBody) : Promise<PasswordComplexityResponseBody> {

        request.recaptchaToken = await this.recaptchaChallengeAsync('authentication_password_complexity');

        const response = await this.makeRequestAsync({
            controller: 'authentication',
            action: 'get-password-complexity',
            method: 'post',
            body: request});
        
        return response as PasswordComplexityResponseBody;
    }

    public async verifyEmailAsync(request: EmailVerifyRequestBody) : Promise<void> {

        request.recaptchaToken = await this.recaptchaChallengeAsync('authentication_verify_email');

        await this.makeRequestAsync({
            controller: 'authentication',
            action: 'verify-email',
            method: 'post',
            body: request});
    }

    /**
     * Gets the current logged in user information and validates the current in progress order token.
     * @param request The request content.
     * @returns A promise that will return the response content.
     */
    public async getTokenInfo(request: GetTokenInfoRequest) : Promise<GetTokenInfoResponse> {

        const response = await this.makeRequestAsync({
            controller: 'authentication', 
            action: 'get-token-info', 
            method: 'post',
            body: request
        });
        return response as GetTokenInfoResponse;
    }


    /**
     * Updates the password for the currently logged on user.
     * @param request The request content.
     * @returns A promise that will return the response content.
     */
     public async updatePasswordAsync(request: UpdatePasswordRequest) : Promise<void> {

        request.recaptchaToken = await this.recaptchaChallengeAsync("update_password");

        const response = await this.makeRequestAsync({
            controller: 'authentication', 
            action: 'update-password', 
            method: 'post',
            body: request
        });
    }

    




}
